<template>
  <div class="complete">
    <div>
      <svg
        class="icon"
        aria-hidden="true"
      >
        <use xlink:href="#icon-gou1"></use>
      </svg>
    </div>
    <div class="large">支付成功</div>
    <!-- <div class="thanks large">感谢您参与本次的调查</div> -->
    <van-button
      class="submit"
      size="large"
      round
      @click="home()"
    >返回首页</van-button>
  </div>
</template>

<script>
export default {
  mounted () {
  },
  methods: {
    home () {
      let data = this.$router.options.routes.find(item => item.redirect != undefined)
      this.$router.push(data.redirect)
      // this.$router.push(data.redirect)
    }
  },
}
</script>

<style lang="less" scoped>
.complete {
  text-align: center;
  .icon {
    height: 100px;
    width: 100vw;
    margin: 2rem auto;
  }
  .thanks {
    color: rgba(223, 223, 223, 0.937);
    margin-top: 10px;
  }
  .submit {
    width: 300px;
    margin-top: 50px;
    background-color: @lightColor;
  }
}
</style>